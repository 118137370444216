<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Sign In</div>
      <a-form
        :model="loginForm"
        :rules="rules"
        layout="vertical"
        class="mb-4"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="email">
          <a-input
            v-model:value="loginForm.email"
            placeholder="Email Address"
          />
        </a-form-item>
        <a-form-item name="password">
          <a-input
            v-model:value="loginForm.password"
            placeholder="Password"
            type="password"
          />
        </a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          class="text-center w-100"
          :loading="loading"
        >
          <strong>Sign in</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/forgot-password" class="vb__utils__link">
        Forgot Password?
      </router-link>
    </div>
    <!-- <div class="text-center pt-2 mb-auto">
      <span class="mr-2">Don't have an account?</span>
      <router-link to="/auth/register" class="vb__utils__link">
        Sign up
      </router-link>
    </div> -->
  </div>
</template>
<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

export default {
  name: "LoginBox",
  setup() {
    const store = useStore();
    const settings = computed(() => store.getters.settings);
    const loading = computed(() => store.getters["user/user"].loading);
    const rules = {
      email: [
        {
          required: true,
          message: "Please input your email!",
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: "Please input password!",
          trigger: "change",
        },
      ],
    };
    const loginForm = reactive({
      email: "",
      password: "",
    });

    const changeAuthProvider = (value) => {
      store.commit("CHANGE_SETTING", { setting: "authProvider", value });
    };

    const handleFinish = (values) => {
      store.dispatch("user/LOGIN", { payload: values });
    };
    const handleFinishFailed = (errors) => {
      console.log(errors);
    };
    let urlParams = new URLSearchParams(window.location.search);
    var token = urlParams.get("token");
    console.log(urlParams.has("token"));

    return {
      settings,
      loading,
      rules,
      loginForm,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
    };
  },
  mounted() {
    this.getToken();
  },
  methods: {
    getToken() {
      console.log(this.$route.query.token);
    },
  },
};
</script>
<style lang="scss" module>
@import "@/components/Auth/style.module.scss";
</style>
